.registration {
  position: relative;

  &::after {
    right: 16rem;
    top: 30rem;
    width: 70rem;
    height: 45rem;

    @media (max-width: em(992)) {
      display: none;
    }
  }

  &__wrapper {
    position: relative;
    align-items: center;
    min-height: 78rem;
    gap: 3rem;

    @media (max-width: em(1390)) {
      min-height: initial;
    }

    @media (max-width: em(992)) {
      flex-direction: column-reverse;
      align-items: initial;
    }
  }

  &__image {
    position: absolute;
    left: -30rem;
    top: 50%;
    width: 87rem;
    height: 74rem;
    transform: translateY(-50%);

    // &::after {
    //   content: '';
    //   position: absolute;
    //   left: 0;
    //   top: 11.5rem;
    //   z-index: -1;
    //   width: 70rem;
    //   height: 70rem;
    //   background-color:  rgba(245, 67, 238, 0.25);
    //   filter: blur(14rem);

    //   @media (max-width: em(992)) {
    //     display: none;
    //   }
    // }

    @media (max-width: em(1390)) {
      position: initial;
      left: initial;
      top: initial;
      width: initial;
      height: initial;
      max-width: 87rem;
      transform: initial;
    }

    @media (max-width: em(992)) {
      margin-right: initial !important;
    }
  }

  &__info {
    margin-left: auto;
    max-width: 57.5rem;
    gap: 2.5rem;

    @media (max-width: em(1390)) {
      max-width: 50rem;
    }

    @media (max-width: em(992)) {
      margin-left: initial;
      max-width: initial !important;
    }
  }

  &__title {
    // margin-bottom: 2.5rem;
    max-width: 39rem;
    text-transform: uppercase;

    @media (max-width: em(1390)) {
      max-width: initial;
    }
  }

  &__text {
    // margin-bottom: 2.5rem;

    // p:not(:last-child) {
    //   margin-bottom: 1.5rem;
    // }
  }

  &__list {
    list-style: decimal;
    // margin-bottom: 2.5rem;
    padding-left: 2rem;
    gap: 1rem;
  }

  &__item {
    line-height: 155%;

    // &:not(:last-child) {
    //   margin-bottom: 1rem;
    // }
  }

  &__item-list {
    list-style: disc;
    padding-left: 2rem;
  }

  &__item-item {
    line-height: 155%;
  }


  &__link {
    @media (max-width: em(992)) {
      margin-right: auto;
      margin-left: auto;
    }

    @media (max-width: em(575)) {
      width: initial;
      min-width: initial;
      text-align: center;
    }
  }
}

