.app {

  &__wrapper {
    position: relative;
    margin-bottom: 5rem;
    gap: 3rem;

    @media (max-width: em(992)) {
      flex-direction: column;
    }

    &::after {
      right: 0;
      top: 18rem;
      width: 45rem;
      height: 45rem;
    }
  }

  &__content {
    max-width: 58rem;
    gap: 2.5rem;

    @media (max-width: em(992)) {
      max-width: initial;
    }
  }

  &__title {
  }

  &__text {
    gap: 2.5rem;
  }

  &__link {
  }

  &__image {
  }

  &__bottom {
    gap: 2.5rem;
  }
}
