.games {

  &::before {
    left: -27rem;
    top: 50%;
    width: 55rem;
    height: 55rem;
    transform: translateY(-50%);
  }
  &__wrapper {
    position: relative;

    @media (max-width: em(1690)) {
      align-items: center;
    }

    @media (max-width: em(992)) {
      flex-direction: column-reverse;
      gap: 2rem;
    }
  }

  &__image {
    position: absolute;
    left: -32rem;
    top: 50%;
    width: 83rem;
    height: 62.5rem;
    transform: translateY(-50%);

    @media (max-width: em(1690)) {
      position: initial;
      left: initial;
      top: initial;
      margin-right: 3rem;
      width: initial;
      transform: initial;
      height: initial;
      max-width: 83rem;
    }

    @media (max-width: em(992)) {
      margin-right: auto !important;
      margin-left: auto;
    }
  }

  &__info {
    margin-left: auto;
    max-width: 67.5rem;
    gap: 2.5rem;

    @media (max-width: em(1690)) {
      max-width: 55rem;
    }

    @media (max-width: em(1200)) {
      max-width: 50rem;
    }

    @media (max-width: em(992)) {
      max-width: initial !important;
    }
  }

  &__text-wrapper {
    gap: 2.5rem;
  }

  &__text {
    gap: 2rem;
  }

  &__table {
    overflow: auto;
    border: 0.1rem solid var(--clr-primary-500);
    border-radius: 3.3rem;
    background-color: var(--clr-primary-700);

    table {
      border-collapse: collapse;

      thead {
        th {
          padding-top: 1.5rem;
          padding-right: 3rem;
          padding-bottom: 1.5rem;
          padding-left: 3rem;
          text-align: start;
        }
      }

      td {
        vertical-align: top;
        padding-top: 1.5rem;
        padding-right: 3rem;
        padding-bottom: 1.5rem;
        padding-left: 3rem;
        line-height: 155%;

        &:first-child {
          min-width: 30rem;

          @media (max-width: em(1690)) {
            min-width: 20rem;
          }

          @media (max-width: em(1200)) {
            min-width: 15rem !important;
          }

          @media (max-width: em(575)) {
            min-width: 10rem !important;
          }
        }

        @media (max-width: em(575)) {
          padding-right: 1.5rem;
          padding-left: 1.5rem;
        }
      }
    }
  }

  &__table-list {
    list-style: disc;
    padding-left: 2rem;
  }

  &__table-item {
    line-height: 155%;
  }
}
