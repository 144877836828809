.footer {
  padding-bottom: 10rem;

  @media (max-width: em(1120)) {
    padding-top: 3rem;
    padding-bottom: 3rem;
  }

  &__wrapper {
    display: flex;
    gap: 2.5rem;

    @media (max-width: em(1120)) {
      flex-direction: column;
    }
  }

  &__menu {
    gap: 2rem;

    @media (max-width: em(767)) {
      flex-direction: column;
    }
  }

  &__item {
    position: relative;

    a {
      font-weight: 500;
      font-size: 1.6rem;
      line-height: 110%;
      color: var(--clr-default-100);
    }

    &::before {
      content: "";
      position: absolute;
      left: 50%;
      bottom: -4px;
      width: 0;
      height: 2px;
      background-color: var(--clr-primary-310);
      transform: translate(-50%, 0);
      transition: width 0.3s;
    }

    @media (hover: hover) {
      &:hover::before {
        width: 100%;
        transition: width 0.3s;
      }
    }
  }
}
