.resume {
  position: relative;
  margin-top: 3rem;
  margin-bottom: -18rem;
  padding-top: 7rem;
  min-height: 100rem;

  @media (max-width: em(1120)) {
    margin-bottom: initial;
    padding-bottom: 7rem;
    min-height: initial;
  }

  @media (max-width: em(992)) {
    margin-top: 5rem;
    padding-top: initial;
    padding-bottom: initial;
  }

  &::before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    z-index: -1;
    width: 100%;
    height: 100%;
    background-image: -webkit-image-set(url(../img/resume/bg@2x.jpg) 2x, url(../img/resume/bg@1x.jpg) 1x);
    background-size: cover;
    background-repeat: no-repeat;

    @media (max-width: em(1120)) {
      display: none;
    }
  }

  &__wrapper {
  }

  &__content {
    position: relative;
    overflow: hidden;
    border: 0.1rem solid var(--clr-primary-550);
    border-radius: 3.3rem;
    padding: 10rem;
    min-height: 67rem;
    background-color: var(--clr-default-600);

    &::before {
      left: 50%;
      bottom: -28rem;
      width: 55rem;
      height: 55rem;
      transform: translateX(-50%);

      @media (max-width: em(992)) {
        display: none;
      }
    }

    @media (max-width: em(992)) {
      padding: 3rem;
      gap: 3rem;
    }

    @media (max-width: em(575)) {
      border-radius: 2rem;
      padding: 1.5rem;
    }
  }

  &__info {
    gap: 2.5rem;
  }

  &__title {
  }

  &__text {
    z-index: 1;
    gap: 2rem;
  }

  &__image {
    position: absolute;
    left: 50%;
    bottom: -16rem;
    width: 84rem;
    height: 40rem;
    transform: translateX(-50%);

    @media (max-width: em(992)) {
      position: initial;
      left: initial;
      bottom: initial;
      width: initial;
      height: initial;
      max-width: 84rem;
      transform: initial;
    }
  }
}

