.slots {

  &__wrapper {
    gap: 4rem;
  }

  &__content {
    gap: 2.5rem;
  }

  &__text {
    gap: 2.5rem;
  }

  &__table {

    &::before {
      right: 0;
      top: 0;
      width: 45rem;
      height: 45rem;
    }

    table {
      thead {
        th {
          &:last-child {
            min-width: 20rem;
          }
        }
      }
    }

    @media (max-width: em(767)) {
      overflow-x: auto;
    }
  }
}