.vip {

  &::before {
    right: 0;
    bottom: -25rem;
    width: 55rem;
    height: 55rem;
  }

  &__wrapper {
    align-items: center;
    gap: 2.5rem;

    @media (max-width: em(992)) {
      flex-direction: column;
    }
  }

  &__content {
    min-width: 58rem;
    gap: 2.5rem;

    @media (max-width: em(1790)) {
      min-width: initial;
      max-width: 50rem;
    }

    @media (max-width: em(992)) {
      max-width: initial !important;
    }
  }

  &__title {
  }

  &__text {
    gap: 2.5rem;
  }

  &__image {
    flex-shrink: 0;
    max-width: 88rem;

    @media (max-width: em(1790)) {
      flex-shrink: initial;
    }
  }
}
