.header {
  z-index: 5;
  min-height: 13rem;

  &__wrapper {
   gap: 2rem;
  }

  &__link {
    @media (max-width: em(1080)) {
      margin-left: auto;
    }
  }

  @media (max-width: em(767)) {
    min-height: 10rem;
  }
}
