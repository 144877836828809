.info {
  position: relative;
  z-index: 1;
  overflow: hidden;
  background-color: var(--clr-default-500);

  &__wrapper {
    gap: 5rem;
  }

  &__wrapper-text {
    gap: 2.5rem;
  }

  &__text {
    gap: 2.5rem;
  }

  &__table-wrapper {
    position: relative;
    padding-top: 2.5rem;
    padding-bottom: 2.5rem;

    &::before, &::after {
      content: '';
      position: absolute;
      left: 50%;
      width: 75%;
      height: 0.1rem;
      background-image: url(../img/ui/line-decor.svg);
      background-size: cover;
      background-repeat: no-repeat;
      transform: translateX(-50%);

      @media (max-width: em(992)) {
        display: none;
      }
    }

    &::before {
      top: 0;
      width: 70%;
    }

    &::after {
      bottom: 0;
      width: 100%;
    }

    @media (max-width: em(992)) {
      padding-top: initial;
      padding-bottom: initial;
    }
  }

  &__table {
    position: relative;
    overflow: auto;
    border: 0.1rem solid var(--clr-primary-500);
    border-radius: 6rem;
    padding-top: 4rem;
    padding-right: 3rem;
    padding-bottom: 4rem;
    padding-left: 3rem;
    background-color: var(--clr-primary-700);

    table {
      border-collapse: collapse;
    }

    tbody {
      tr {
        border-bottom: 0.1rem solid var(--clr-primary-500);
      }

      td {
        padding-top: 0.8rem;
        padding-right: 1.2rem;
        padding-bottom: 0.8rem;
        padding-left: 1.2rem;
        line-height: 135%;

        &:first-child {
          vertical-align: top;
          min-width: 27rem;
          font-weight: 500;
          word-break: break-word;

          @media (max-width: em(767)) {
            min-width: 20rem;
          }

          @media (max-width: em(575)) {
            min-width: 16rem;
          }
        }

        &:last-child {
          @media (max-width: em(575)) {
            min-width: 30rem;
          }
        }

        a {
          color: var(--clr-default-100);
        }
      }
    }

    @media (max-width: em(575)) {
      margin-bottom: 3rem;
      border-radius: 2rem;
      padding-top: 2rem;
      padding-right: 1.5rem;
      padding-bottom: 2rem;
      padding-left: 1.5rem;
    }
  }

  &__link {
    margin-right: auto;
    margin-left: auto;
  }

  @media (max-width: em(1080)) {
    z-index: 0;
  }
}
