$burgerMQ: em(1080);

.menu {
  &__list {
   gap: 2rem;

    @media (max-width: $burgerMQ) {
      flex-direction: column;
      margin: auto;
      gap: 3rem;
      padding-block: 4rem;
    }
  }

  &__item {
    position: relative;

    &::before {
      content: "";
      position: absolute;
      left: 50%;
      bottom: -4px;
      width: 0;
      height: 2px;
      background-color: var(--clr-primary-310);
      transform: translate(-50%, 0);
      transition: width 0.3s;
    }

    @media (hover: hover) {
      &:hover::before {
        width: 100%;
        transition: width 0.3s;
      }
    }

    a {
      font-weight: 500;
      font-size: 1.6rem;
      line-height: 110%;
      color: var(--clr-default-100);

      @media (max-width: em(1080)) {
        font-weight: 600;
        font-size: 1.8rem;
      }
    }

    // &:not(:last-child) {
    //   margin-right: 2rem;

    //   @media (max-width: em(1200)) {
    //     margin-right: 1.5rem;
    //   }

    //   @media (max-width: $burgerMQ) {
    //     margin-right: 0;
    //     margin-bottom: 3rem;
    //   }
    // }

    &--mobile {
      p {
        font-weight: 600;
        color: var(--clr-default-200);
      }
    }
  }

  @media (max-width: $burgerMQ) {
    position: fixed;
    right: -100%;
    top: 0;
    z-index: 10;
    display: flex;
    overflow: auto;
    margin-right: initial;
    width: 100%;
    height: 100%;
    max-width: 32rem;
    background-color: var(--clr-default-500);
    transition: right var(--transition);

    &--active {
      right: 0;
      transition: right var(--transition);
    }
  }
}
