.live {
  &__content-wrapper {
    gap: 6rem;
  }

  &__wrapper {
    align-items: center;
    gap: 3rem;

    &::after {
      left: 50%;
      top: 18rem;
      width: 85rem;
      height: 65rem;
      transform: translateX(-50%);
    }

    @media (max-width: em(992)) {
      flex-direction: column;
    }
  }

  &__content {
    min-width: 48rem;
    gap: 3rem;

    @media (max-width: em(1560)) {
      min-width: initial;
      max-width: 48rem;
    }

    @media (max-width: em(992)) {
      max-width: initial !important;
    }
  }

  &__text {
    gap: 2rem;
  }

  &__image {
    flex-shrink: 0;
    max-width: 85.5rem;

    @media (max-width: em(1560)) {
      flex-shrink: initial;
    }
  }

  //body

  &__body {
    justify-content: space-between;
    gap: 3rem;

    @media (max-width: em(992)) {
      flex-direction: column;
      align-items: initial;
    }
  }

  &__body-left {
    gap: 3rem;
  }

  &__body-right {
    gap: 6rem;
  }

  &__body-title {
    font-size: fluid-font(2rem, 1.8rem);
    text-transform: uppercase;

    @media (max-width: em(992)) {
      text-align: center;
    }
  }

  &__body-image {
    max-width: 57.5rem;

    @media (max-width: em(992)) {
      margin-right: auto;
      margin-left: auto;
    }
  }

  &__list {
    display: grid;
    grid-gap: 2.5rem;
    grid-template-columns: repeat(2, 1fr);

    @media (max-width: em(575)) {
      grid-template-columns: 1fr;
      gap: 1.5rem;
    }
  }

  &__item {
    font-size: fluid-font(2rem, 1.8rem);
  }

  &__text-arrow {
    position: relative;
    margin-right: auto;
    margin-left: auto;
    max-width: 25.5rem;
    font-size: 1.4rem;
    line-height: 130%;

    &:before {
      content: "";
      position: absolute;
      left: -17rem;
      bottom: -2rem;
      width: 16rem;
      height: 3.2rem;
      background-image: url(../img/live/arrow.svg);
      background-size: cover;
      background-repeat: no-repeat;
    }

    @media (max-width: em(1280)) {
      margin-right: initial;
    }

    @media (max-width: em(992)) {
      display: none;
    }
  }
}
