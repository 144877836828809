.visually-hidden {
  position: absolute;
  overflow: hidden;
  margin: -1px;
  border: 0;
  padding: 0;
  width: 1px;
  height: 1px;
  clip: rect(0 0 0 0);
}

.section {
  padding-top: 13rem;

  @media (max-width: em(992)) {
    padding-top: 8rem;
  }

  @media (max-width: em(575)) {
    padding-top: 5rem;
  }
}

.table {
  border: 0.1rem solid var(--clr-primary-500);
  border-radius: 3.3rem;
  background-color: var(--clr-primary-700);

  table {
    border-collapse: collapse;

    @media (max-width: em(767)) {
      min-width: 50rem;
    }

    thead {
      th {
        padding-top: 1.5rem;
        padding-right: 3rem;
        padding-bottom: 1.5rem;
        padding-left: 3rem;
        line-height: 155%;
        text-align: start;

        &:first-child {
          min-width: 30rem;

          @media (max-width: em(992)) {
            min-width: 20rem;
          }

          @media (max-width: em(767)) {
            min-width: 12rem;
          }
        }

        &:nth-child(2) {
          width: 50%;

          @media (max-width: em(767)) {
            width: initial;
          }
        }

        &:nth-child(3) {
          width: 50%;

          @media (max-width: em(767)) {
            width: initial;
          }
        }

        @media (max-width: em(575)) {
          padding-right: 1.5rem;
          padding-left: 1.5rem;
        }
      }
    }

    tbody {
      tr {
        td {
          vertical-align: top;
          padding-top: 1.5rem;
          padding-right: 3rem;
          padding-bottom: 1.5rem;
          padding-left: 3rem;
          line-height: 155%;

          p {
            &:not(:last-child) {
              margin-bottom: 1.5rem;
            }
          }

          @media (max-width: em(575)) {
            padding-right: 1.5rem;
            padding-left: 1.5rem;
          }
        }
      }
    }
  }
}

.main__link {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 1rem;
  border-top-left-radius: 5% 50%;
  border-top-right-radius: 5% 50%;
  border-bottom-right-radius: 5% 50%;
  border-bottom-left-radius: 5% 50%;
  padding-right: 3.5rem;
  padding-left: 3.5rem;
  width: max-content;
  min-width: 23rem;
  min-height: 6.8rem;
  font-weight: 600;
  font-size: fluid-font(1.8rem, 1.6rem);
  text-transform: uppercase;
  color: var(--clr-default-200);
  background-color: var(--clr-primary-300);
  transition: background-color var(--transition);

  span {
    z-index: 1;
  }

  &:before,
  &::after {
    content: "";
    position: absolute;
    left: 50%;
  }

  &::before {
    bottom: 0;
    z-index: -1;
    border-top-left-radius: 5% 50%;
    border-top-right-radius: 5% 50%;
    border-bottom-right-radius: 5% 50%;
    border-bottom-left-radius: 5% 50%;
    width: 100%;
    height: 6.5rem;
    background-color: var(--clr-primary-350);
    transform: translate(-50%, 13%);
    transition: transform var(--transition);
  }

  &::after {
    top: 50%;
    width: 18rem;
    height: 6.5rem;
    background-image: linear-gradient(
      90deg,
      rgba(255, 255, 255, 0) 0%,
      rgba(255, 255, 255, 0.45) 48.33%,
      rgba(255, 255, 255, 0) 100%
    );
    transform: translate(-50%, -50%);
  }

  @media (max-width: em(575)) {
    min-width: 100%;
  }

  @media (hover: hover) {
    &:hover {
      background-color: var(--clr-primary-310);
      transition: background-color var(--transition);
      &::before {
        transform: translate(-50%, 0%);
        transition: transform var(--transition);
      }
    }
  }
}

.line__decor {
  &::before {
    content: "";
    position: absolute;
    height: 0.1rem;
    background-image: url(../img/ui/line-decor.svg);
    background-size: cover;
    background-repeat: no-repeat;
  }
}

.radial-purple {
  position: relative;

  &::before {
    content: "";
    position: absolute;
    z-index: -1;
    background-color: rgba(245, 67, 238, 0.25);
    filter: blur(26rem);
  }
}

.radial-red {
  position: relative;

  &::after {
    content: "";
    position: absolute;
    z-index: -1;
    background-color: rgba(245, 67, 67, 0.17);
    filter: blur(26rem);
  }
}

.radial-blue {
  position: relative;

  &::before {
    content: "";
    position: absolute;
    z-index: -1;
    background-color: var(--clr-primary-400);
    filter: blur(26rem);
    mix-blend-mode: color-dodge;
  }
}

.radial-pink {
  position: relative;

  &::before {
    content: "";
    position: absolute;
    z-index: 0;
    background-color: #E32296;
    opacity: 0.77;
    filter: blur(26rem);
  }
}
