// <МИКСИНЫ>==================================================================
@import "./settings/mixins";

// </МИКСИНЫ>=================================================================

// ФУНКЦИИ====================================================================
@import "./settings/functions";

// </ФУНКЦИИ>=================================================================

// <Подключаем шрифты>========================================================
@import "./settings/fonts";

// </Подключаем шрифты>=======================================================

// <ОБНУЛЕНИЕ>================================================================
@import "./settings/normalize";

// </ОБНУЛЕНИЕ>===============================================================

// <ПЕРЕМЕННЫЕ>===============================================================
$containerWidth: 121rem;

:root {
  // base
  --font-family: "Poppins", sans-serif;
  --font-weight: 400;
  --content-width: 118rem;
  --container-offset: 1.5rem;
  --container-width: calc(var(--content-width) + (var(--container-offset) * 2));
  --transition: 0.3s;

  // colors
  --clr-default-100: #fff;
  --clr-default-200: #24092E;
  --clr-default-300: rgba(0, 0, 0, 0.25);
  --clr-default-400: #1A0119;
  --clr-default-450: #160071;
  --clr-default-460: #110017;
  --clr-default-500: #14001C;
  --clr-default-600: #0F0015;
  --clr-default-700: #000;

  --clr-primary-300: #FFA601;
  --clr-primary-310: #eeb242;
  --clr-primary-350: #704B09;
  --clr-primary-400: #4361F5;
  --clr-primary-450: #D60E7A;
  --clr-primary-500: #37263E;
  --clr-primary-550: rgba(255, 255, 255, 0.15);
  --clr-primary-600: rgba(47, 18, 57, 0.3);
  --clr-primary-700: rgba(47, 18, 57, 0.7);
  --clr-primary-800: #0F0015;
}

// </ПЕРЕМЕННЫЕ>==============================================================

// <ОБОЛОЧКИ>================================================================
body {
  font-size: 1.4rem;
  color: var(--clr-default-100);
  background-color: var(--clr-default-500);

  &.lock {
    position: fixed;
    left: 0;
    top: 0;
    overflow: hidden;
    width: 100%;
    height: 100vh;
    overscroll-behavior: none;
  }
}

.wrapper {
  display: flex;
  flex-direction: column;
  overflow: hidden;
  width: 100%;
  min-width: 32rem;
  min-height: 100%;

  &::before {
    content: "";
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: 5;
    background-color: rgb(0 0 0 / 50%);
    opacity: 0;
    visibility: hidden;
    transition: opacity var(--transition), visibility var(--transition);
  }

  &[data-overlay="true"]::before {
    opacity: 1;
    visibility: visible;
    transition: opacity var(--transition), visibility var(--transition);
  }
}

main {
  flex-grow: 1;
}

// </ОБОЛОЧКИ>===============================================================

// <СЕТКА>===================================================================
.container {
  margin-right: auto;
  margin-left: auto;
  padding-right: var(--container-offset);
  padding-left: var(--container-offset);
  width: 100%;
  max-width: var(--container-width);
}

// Components - UI
@import "./ui";
@import "./stack";
@import "./typography";

// Components - UI

// Components - UI
@import "./components/ui/menu";
@import "./components/ui/logo";
@import "./components/ui/burger";

// Components - UI

// Components - Common
@import "./components/common/header";
@import "./components/common/footer";

// Components - Common

// Components - Pages
@import "./components/indexPage/hero";
@import "./components/indexPage/info";
@import "./components/indexPage/benefits";
@import "./components/indexPage/registration";
@import "./components/indexPage/app";
@import "./components/indexPage/bonus";
@import "./components/indexPage/vip";
@import "./components/indexPage/tournament";
@import "./components/indexPage/slots";
@import "./components/indexPage/games";
@import "./components/indexPage/live";
@import "./components/indexPage/deposit";
@import "./components/indexPage/resume";

// Components - Pages

// </СЕТКА>==================================================================

// <ПОДКЛЮЧЕНИЕ ФАЙЛОВ СТИЛЕЙ СТРАНИЦ И ЭЛЕМЕНТОВ>===========================

// <ПОДКЛЮЧЕНИЕ ФАЙЛОВ СТИЛЕЙ СТРАНИЦ И ЭЛЕМЕНТОВ>===========================
