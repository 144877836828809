.benefits {
  &::before {
    left: 26rem;
    top: 49rem;
    width: 65rem;
    height: 65rem;

    @media (max-width: em(1560)) {
      display: none;
    }
  }

  &__wrapper {
    position: relative;
    gap: 5rem;

    @media (max-width: em(575)) {
      gap: 3rem;
    }
  }

  &__body {
    display: flex;

    @media (max-width: em(1560)) {
      align-items: center;
      gap: 3rem;
    }

    @media (max-width: em(992)) {
      flex-direction: column;
    }
  }

  &__body-wrapper {
    max-width: 79rem;
    gap: 2.5rem;

    @media (max-width: em(1560)) {
      flex-shrink: 0;
      max-width: 60rem;
    }

    @media (max-width: em(992)) {
      margin-right: initial !important;
      min-width: 100%;
      max-width: initial !important;
    }

    @media (max-width: em(767)) {
      flex-direction: column;
    }
  }

  &__body-content {
    gap: 2.5rem;
  }

  &__body-title {
    font-size: fluid-font(2rem, 1.8rem);
    line-height: 155%;
    text-transform: uppercase;
  }

  &__plus-list {
    gap: 2rem;
  }

  &__plus-item {
    position: relative;
    display: flex;
    align-items: center;
    border: 1px solid var(--clr-primary-500);
    padding-top: 2rem;
    padding-right: 2rem;
    padding-bottom: 2rem;
    padding-left: 8rem;
    min-height: 9rem;
    background-color: var(--clr-default-300);

    &::before {
      top: 0;
      width: 70%;
      background-size: contain;
    }

    &::after {
      content: "";
      position: absolute;
      left: 50%;
      bottom: -1rem;
      width: 100%;
      height: 2rem;
      background-image: radial-gradient(
        50% 50% at 50% 50%,
        rgba(210, 166, 255, 0.55) 0%,
        rgba(210, 166, 255, 0) 98.85%
      );
      filter: blur(5.5px);
      mix-blend-mode: screen;
      transform: translateX(-50%);
    }

    @media (max-width: em(575)) {
      padding-left: 6rem;
    }
  }

  &__item-text {
    position: relative;
    line-height: 155%;

    &--1,
    &--2,
    &--3,
    &--4,
    &--5,
    &--6,
    &--7,
    &--8,
    &--9,
    &--10 {
      position: relative;
      &::before {
        content: "";
        position: absolute;
        left: -6.5rem;
        top: 50%;
        width: 5.2rem;
        height: 5.2rem;
        background-size: cover;
        background-repeat: no-repeat;
        transform: translateY(-50%);

        @media (max-width: em(575)) {
          left: -4.5rem;
          width: 3.2rem;
          height: 3.2rem;
        }
      }
    }

    &--1 {
      &::before {
        background-image: url(../img/benefits/decor1.svg);
      }
    }

    &--2 {
      &::before {
        background-image: url(../img/benefits/decor2.svg);
      }
    }

    &--3 {
      &::before {
        background-image: url(../img/benefits/decor3.svg);
      }
    }

    &--4 {
      &::before {
        background-image: url(../img/benefits/decor4.svg);
      }
    }

    &--5 {
      &::before {
        background-image: url(../img/benefits/decor5.svg);
      }
    }

    &--6 {
      &::before {
        background-image: url(../img/benefits/decor6.svg);
      }
    }

    &--7 {
      &::before {
        background-image: url(../img/benefits/decor7.svg);
      }
    }

    &--8 {
      &::before {
        background-image: url(../img/benefits/decor8.svg);
      }
    }

    &--9 {
      &::before {
        background-image: url(../img/benefits/decor9.svg);
      }
    }

    &--10 {
      &::before {
        background-image: url(../img/benefits/decor10.svg);
      }
    }
  }

  &__image {
    position: absolute;
    right: -48.8rem;
    top: 6.5rem;
    width: 85rem;
    height: 63rem;

    @media (max-width: em(1560)) {
      position: initial;
      right: initial;
      top: initial;
      width: initial;
      height: initial;
      max-width: 84rem;
    }
  }
}
