/* stylelint-disable */

/* stylelint-disable */

/* stylelint-disable scss/operator-no-unspaced */

/* stylelint-disable scss/dollar-variable-colon-newline-after */

@font-face {
  font-family: "Poppins";
  src: url("../fonts/Poppins-Bold.woff2") format("woff2");
  font-weight: 700;
  font-display: swap;
  font-style: normal;
}

@font-face {
  font-family: "Poppins";
  src: url("../fonts/Poppins-Medium.woff2") format("woff2");
  font-weight: 500;
  font-display: swap;
  font-style: normal;
}

@font-face {
  font-family: "Poppins";
  src: url("../fonts/Poppins-Regular.woff2") format("woff2");
  font-weight: 400;
  font-display: swap;
  font-style: normal;
}

@font-face {
  font-family: "Poppins";
  src: url("../fonts/Poppins-SemiBold.woff2") format("woff2");
  font-weight: 600;
  font-display: swap;
  font-style: normal;
}

html {
  font-family: var(--font-family);
  font-weight: var(--font-weight);
  font-size: 62.5%;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  scroll-behavior: smooth;
}

html,
body {
  height: 100%;
}

body {
  -moz-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
}

*,
*::before,
*::after {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

pre {
  font-family: monospace;
  font-size: 1em;
}

a {
  text-decoration: none;
}

img {
  height: auto;
  max-width: 100%;
  object-fit: cover;
}

button {
  border: none;
  text-align: left;
  background-color: transparent;
  cursor: pointer;
}

ul,
ol {
  list-style: none;
}

input {
  -webkit-appearance: none;
  appearance: none;
  border: none;
  border-radius: 0;
  background-color: #fff;
}

input::-webkit-search-decoration,
input::-webkit-search-cancel-button,
input::-webkit-search-results-button,
input::-webkit-search-results-decoration {
  display: none;
}

.js-focus-visible :focus:not(.focus-visible) {
  outline: none;
}

.focus-visible {
  outline: auto 1px;
}

.ios .lock {
  position: relative;
}

/* Forms
   ========================================================================== */

/**
 * 1. Change the font styles in all browsers.
 * 2. Remove the margin in Firefox and Safari.
 */

button,
input,
optgroup,
select,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: 100%;
  line-height: 1.15;
}

/**
 * Remove the inheritance of text transform in Edge, Firefox, and IE.
 * 1. Remove the inheritance of text transform in Firefox.
 */

button,
select {
  text-transform: none;
}

/**
 * Correct the inability to style clickable types in iOS and Safari.
 */

button,
[type=button],
[type=reset],
[type=submit] {
  -webkit-appearance: button;
}

/**
 * Remove the inner border and padding in Firefox.
 */

button::-moz-focus-inner,
[type=button]::-moz-focus-inner,
[type=reset]::-moz-focus-inner,
[type=submit]::-moz-focus-inner {
  border-style: none;
  padding: 0;
}

/**
 * Restore the focus styles unset by the previous rule.
 */

button:-moz-focusring,
[type=button]:-moz-focusring,
[type=reset]:-moz-focusring,
[type=submit]:-moz-focusring {
  outline: 1px dotted ButtonText;
}

:root {
  --font-family: "Poppins", sans-serif;
  --font-weight: 400;
  --content-width: 118rem;
  --container-offset: 1.5rem;
  --container-width: calc(var(--content-width) + (var(--container-offset) * 2));
  --transition: 0.3s;
  --clr-default-100: #fff;
  --clr-default-200: #24092E;
  --clr-default-300: rgba(0, 0, 0, 0.25);
  --clr-default-400: #1A0119;
  --clr-default-450: #160071;
  --clr-default-460: #110017;
  --clr-default-500: #14001C;
  --clr-default-600: #0F0015;
  --clr-default-700: #000;
  --clr-primary-300: #FFA601;
  --clr-primary-310: #eeb242;
  --clr-primary-350: #704B09;
  --clr-primary-400: #4361F5;
  --clr-primary-450: #D60E7A;
  --clr-primary-500: #37263E;
  --clr-primary-550: rgba(255, 255, 255, 0.15);
  --clr-primary-600: rgba(47, 18, 57, 0.3);
  --clr-primary-700: rgba(47, 18, 57, 0.7);
  --clr-primary-800: #0F0015;
}

body {
  font-size: 1.4rem;
  color: var(--clr-default-100);
  background-color: var(--clr-default-500);
}

body.lock {
  position: fixed;
  left: 0;
  top: 0;
  overflow: hidden;
  width: 100%;
  height: 100vh;
  overscroll-behavior: none;
}

.wrapper {
  display: flex;
  flex-direction: column;
  overflow: hidden;
  width: 100%;
  min-width: 32rem;
  min-height: 100%;
}

.wrapper::before {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 5;
  background-color: rgba(0, 0, 0, 0.5);
  opacity: 0;
  visibility: hidden;
  transition: opacity var(--transition), visibility var(--transition);
}

.wrapper[data-overlay=true]::before {
  opacity: 1;
  visibility: visible;
  transition: opacity var(--transition), visibility var(--transition);
}

main {
  flex-grow: 1;
}

.container {
  margin-right: auto;
  margin-left: auto;
  padding-right: var(--container-offset);
  padding-left: var(--container-offset);
  width: 100%;
  max-width: var(--container-width);
}

.visually-hidden {
  position: absolute;
  overflow: hidden;
  margin: -1px;
  border: 0;
  padding: 0;
  width: 1px;
  height: 1px;
  clip: rect(0 0 0 0);
}

.section {
  padding-top: 13rem;
}

.table {
  border: 0.1rem solid var(--clr-primary-500);
  border-radius: 3.3rem;
  background-color: var(--clr-primary-700);
}

.table table {
  border-collapse: collapse;
}

.table table thead th {
  padding-top: 1.5rem;
  padding-right: 3rem;
  padding-bottom: 1.5rem;
  padding-left: 3rem;
  line-height: 155%;
  text-align: start;
}

.table table thead th:first-child {
  min-width: 30rem;
}

.table table thead th:nth-child(2) {
  width: 50%;
}

.table table thead th:nth-child(3) {
  width: 50%;
}

.table table tbody tr td {
  vertical-align: top;
  padding-top: 1.5rem;
  padding-right: 3rem;
  padding-bottom: 1.5rem;
  padding-left: 3rem;
  line-height: 155%;
}

.table table tbody tr td p:not(:last-child) {
  margin-bottom: 1.5rem;
}

.main__link {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 1rem;
  border-top-left-radius: 5% 50%;
  border-top-right-radius: 5% 50%;
  border-bottom-right-radius: 5% 50%;
  border-bottom-left-radius: 5% 50%;
  padding-right: 3.5rem;
  padding-left: 3.5rem;
  width: max-content;
  min-width: 23rem;
  min-height: 6.8rem;
  font-weight: 600;
  font-size: clamp(
    1.6rem,
    1.5280898876rem + 0.2247191011vw,
    1.8rem
  );
  text-transform: uppercase;
  color: var(--clr-default-200);
  background-color: var(--clr-primary-300);
  transition: background-color var(--transition);
}

.main__link span {
  z-index: 1;
}

.main__link:before,
.main__link::after {
  content: "";
  position: absolute;
  left: 50%;
}

.main__link::before {
  bottom: 0;
  z-index: -1;
  border-top-left-radius: 5% 50%;
  border-top-right-radius: 5% 50%;
  border-bottom-right-radius: 5% 50%;
  border-bottom-left-radius: 5% 50%;
  width: 100%;
  height: 6.5rem;
  background-color: var(--clr-primary-350);
  transform: translate(-50%, 13%);
  transition: transform var(--transition);
}

.main__link::after {
  top: 50%;
  width: 18rem;
  height: 6.5rem;
  background-image: linear-gradient(90deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.45) 48.33%, rgba(255, 255, 255, 0) 100%);
  transform: translate(-50%, -50%);
}

.line__decor::before {
  content: "";
  position: absolute;
  height: 0.1rem;
  background-image: url(../img/ui/line-decor.svg);
  background-size: cover;
  background-repeat: no-repeat;
}

.radial-purple {
  position: relative;
}

.radial-purple::before {
  content: "";
  position: absolute;
  z-index: -1;
  background-color: rgba(245, 67, 238, 0.25);
  filter: blur(26rem);
}

.radial-red {
  position: relative;
}

.radial-red::after {
  content: "";
  position: absolute;
  z-index: -1;
  background-color: rgba(245, 67, 67, 0.17);
  filter: blur(26rem);
}

.radial-blue {
  position: relative;
}

.radial-blue::before {
  content: "";
  position: absolute;
  z-index: -1;
  background-color: var(--clr-primary-400);
  filter: blur(26rem);
  mix-blend-mode: color-dodge;
}

.radial-pink {
  position: relative;
}

.radial-pink::before {
  content: "";
  position: absolute;
  z-index: 0;
  background-color: #E32296;
  opacity: 0.77;
  filter: blur(26rem);
}

.stack {
  display: flex;
}

.stack.column {
  flex-direction: column;
}

.stack.column-reverse {
  flex-direction: column-reverse;
}

.stack.row {
  flex-direction: row;
}

.stack.row-reverse {
  flex-direction: row-reverse;
}

.stack.justify-flex-start {
  justify-content: flex-start;
}

.stack.justify-center {
  justify-content: center;
}

.stack.justify-flex-end {
  justify-content: flex-end;
}

.stack.justify-space-between {
  justify-content: space-between;
}

.stack.justify-space-around {
  justify-content: space-around;
}

.stack.center {
  align-items: center;
  justify-content: center;
}

.stack.align-start {
  align-items: flex-start;
}

.stack.align-center {
  align-items: center;
}

.stack.align-end {
  align-items: flex-end;
}

.stack.circle-center {
  align-items: center;
  justify-content: center;
  border-radius: 50%;
}

.stack.wrap {
  flex-wrap: wrap;
}

.stack.no-wrap {
  flex-wrap: nowrap;
}

.stack.wrap-reverse {
  flex-wrap: wrap-reverse;
}

.typography.h1 {
  font-weight: 700;
  font-size: clamp(
    2.4rem,
    1.1056179775rem + 4.0449438202vw,
    6rem
  );
  line-height: 120%;
}

.typography.h2 {
  font-weight: 600;
  font-size: clamp(
    2rem,
    1.7123595506rem + 0.8988764045vw,
    2.8rem
  );
  line-height: 115%;
  text-transform: uppercase;
}

.typography.h3 {
  font-size: 2.2rem;
}

.typography.h4 {
  font-size: 1.9rem;
}

.typography.h5 {
  font-size: 1.7rem;
}

.typography.h6 {
  font-size: 1.6rem;
}

.typography.p p {
  font-size: 1.4rem;
  line-height: 155%;
}

.typography.caption {
  font-size: 1.2rem;
}

.typography.w-400 {
  font-weight: 400;
}

.typography.w-500 {
  font-weight: 500;
}

.typography.w-600 {
  font-weight: 600;
}

.typography.success {
  color: var(--clr-status-success);
}

.typography.error {
  color: var(--clr-primary-600);
}

.typography.grey {
  color: var(--clr-default-600);
}

.typography.dark {
  color: var(--clr-default-800);
}

.typography.white {
  color: var(--clr-default-100);
}

.typography.uppercase {
  text-transform: uppercase;
}

.typography.center {
  text-align: center;
}

.typography.lh-150 {
  line-height: 150%;
}

.typography .link {
  color: var(--clr-secondary-900);
  transition: color var(--transition);
}

.typography .link.focus-visible {
  outline: none;
  color: var(--clr-secondary-400);
}

.menu__list {
  gap: 2rem;
}

.menu__item {
  position: relative;
}

.menu__item::before {
  content: "";
  position: absolute;
  left: 50%;
  bottom: -4px;
  width: 0;
  height: 2px;
  background-color: var(--clr-primary-310);
  transform: translate(-50%, 0);
  transition: width 0.3s;
}

.menu__item a {
  font-weight: 500;
  font-size: 1.6rem;
  line-height: 110%;
  color: var(--clr-default-100);
}

.menu__item--mobile p {
  font-weight: 600;
  color: var(--clr-default-200);
}

.logo {
  max-width: 15rem;
}

.burger {
  display: none;
}

.header {
  z-index: 5;
  min-height: 13rem;
}

.header__wrapper {
  gap: 2rem;
}

.footer {
  padding-bottom: 10rem;
}

.footer__wrapper {
  display: flex;
  gap: 2.5rem;
}

.footer__menu {
  gap: 2rem;
}

.footer__item {
  position: relative;
}

.footer__item a {
  font-weight: 500;
  font-size: 1.6rem;
  line-height: 110%;
  color: var(--clr-default-100);
}

.footer__item::before {
  content: "";
  position: absolute;
  left: 50%;
  bottom: -4px;
  width: 0;
  height: 2px;
  background-color: var(--clr-primary-310);
  transform: translate(-50%, 0);
  transition: width 0.3s;
}

.hero {
  position: relative;
  min-height: 55.5rem;
}

.hero:before,
.hero:after {
  content: "";
  position: absolute;
  z-index: -1;
  background-color: var(--clr-primary-400);
  filter: blur(14rem);
  mix-blend-mode: color-dodge;
}

.hero:before {
  left: 0;
  top: 0;
  width: 45rem;
  height: 44rem;
  transform: translate(-43%, -83%);
}

.hero:after {
  left: 50%;
  top: 50%;
  width: 32rem;
  height: 32rem;
  transform: translate(-50%, -200%);
}

.hero__wrapper {
  position: relative;
}

.hero__content {
  max-width: 63rem;
  gap: 3rem;
}

.hero__image {
  position: absolute;
  right: -40rem;
  top: -33rem;
  z-index: 0;
  width: 89.5rem;
  height: 78.5rem;
}

.info {
  position: relative;
  z-index: 1;
  overflow: hidden;
  background-color: var(--clr-default-500);
}

.info__wrapper {
  gap: 5rem;
}

.info__wrapper-text {
  gap: 2.5rem;
}

.info__text {
  gap: 2.5rem;
}

.info__table-wrapper {
  position: relative;
  padding-top: 2.5rem;
  padding-bottom: 2.5rem;
}

.info__table-wrapper::before,
.info__table-wrapper::after {
  content: "";
  position: absolute;
  left: 50%;
  width: 75%;
  height: 0.1rem;
  background-image: url(../img/ui/line-decor.svg);
  background-size: cover;
  background-repeat: no-repeat;
  transform: translateX(-50%);
}

.info__table-wrapper::before {
  top: 0;
  width: 70%;
}

.info__table-wrapper::after {
  bottom: 0;
  width: 100%;
}

.info__table {
  position: relative;
  overflow: auto;
  border: 0.1rem solid var(--clr-primary-500);
  border-radius: 6rem;
  padding-top: 4rem;
  padding-right: 3rem;
  padding-bottom: 4rem;
  padding-left: 3rem;
  background-color: var(--clr-primary-700);
}

.info__table table {
  border-collapse: collapse;
}

.info__table tbody tr {
  border-bottom: 0.1rem solid var(--clr-primary-500);
}

.info__table tbody td {
  padding-top: 0.8rem;
  padding-right: 1.2rem;
  padding-bottom: 0.8rem;
  padding-left: 1.2rem;
  line-height: 135%;
}

.info__table tbody td:first-child {
  vertical-align: top;
  min-width: 27rem;
  font-weight: 500;
  word-break: break-word;
}

.info__table tbody td a {
  color: var(--clr-default-100);
}

.info__link {
  margin-right: auto;
  margin-left: auto;
}

.benefits::before {
  left: 26rem;
  top: 49rem;
  width: 65rem;
  height: 65rem;
}

.benefits__wrapper {
  position: relative;
  gap: 5rem;
}

.benefits__body {
  display: flex;
}

.benefits__body-wrapper {
  max-width: 79rem;
  gap: 2.5rem;
}

.benefits__body-content {
  gap: 2.5rem;
}

.benefits__body-title {
  font-size: clamp(
    1.8rem,
    1.7280898876rem + 0.2247191011vw,
    2rem
  );
  line-height: 155%;
  text-transform: uppercase;
}

.benefits__plus-list {
  gap: 2rem;
}

.benefits__plus-item {
  position: relative;
  display: flex;
  align-items: center;
  border: 1px solid var(--clr-primary-500);
  padding-top: 2rem;
  padding-right: 2rem;
  padding-bottom: 2rem;
  padding-left: 8rem;
  min-height: 9rem;
  background-color: var(--clr-default-300);
}

.benefits__plus-item::before {
  top: 0;
  width: 70%;
  background-size: contain;
}

.benefits__plus-item::after {
  content: "";
  position: absolute;
  left: 50%;
  bottom: -1rem;
  width: 100%;
  height: 2rem;
  background-image: radial-gradient(50% 50% at 50% 50%, rgba(210, 166, 255, 0.55) 0%, rgba(210, 166, 255, 0) 98.85%);
  filter: blur(5.5px);
  mix-blend-mode: screen;
  transform: translateX(-50%);
}

.benefits__item-text {
  position: relative;
  line-height: 155%;
}

.benefits__item-text--1,
.benefits__item-text--2,
.benefits__item-text--3,
.benefits__item-text--4,
.benefits__item-text--5,
.benefits__item-text--6,
.benefits__item-text--7,
.benefits__item-text--8,
.benefits__item-text--9,
.benefits__item-text--10 {
  position: relative;
}

.benefits__item-text--1::before,
.benefits__item-text--2::before,
.benefits__item-text--3::before,
.benefits__item-text--4::before,
.benefits__item-text--5::before,
.benefits__item-text--6::before,
.benefits__item-text--7::before,
.benefits__item-text--8::before,
.benefits__item-text--9::before,
.benefits__item-text--10::before {
  content: "";
  position: absolute;
  left: -6.5rem;
  top: 50%;
  width: 5.2rem;
  height: 5.2rem;
  background-size: cover;
  background-repeat: no-repeat;
  transform: translateY(-50%);
}

.benefits__item-text--1::before {
  background-image: url(../img/benefits/decor1.svg);
}

.benefits__item-text--2::before {
  background-image: url(../img/benefits/decor2.svg);
}

.benefits__item-text--3::before {
  background-image: url(../img/benefits/decor3.svg);
}

.benefits__item-text--4::before {
  background-image: url(../img/benefits/decor4.svg);
}

.benefits__item-text--5::before {
  background-image: url(../img/benefits/decor5.svg);
}

.benefits__item-text--6::before {
  background-image: url(../img/benefits/decor6.svg);
}

.benefits__item-text--7::before {
  background-image: url(../img/benefits/decor7.svg);
}

.benefits__item-text--8::before {
  background-image: url(../img/benefits/decor8.svg);
}

.benefits__item-text--9::before {
  background-image: url(../img/benefits/decor9.svg);
}

.benefits__item-text--10::before {
  background-image: url(../img/benefits/decor10.svg);
}

.benefits__image {
  position: absolute;
  right: -48.8rem;
  top: 6.5rem;
  width: 85rem;
  height: 63rem;
}

.registration {
  position: relative;
}

.registration::after {
  right: 16rem;
  top: 30rem;
  width: 70rem;
  height: 45rem;
}

.registration__wrapper {
  position: relative;
  align-items: center;
  min-height: 78rem;
  gap: 3rem;
}

.registration__image {
  position: absolute;
  left: -30rem;
  top: 50%;
  width: 87rem;
  height: 74rem;
  transform: translateY(-50%);
}

.registration__info {
  margin-left: auto;
  max-width: 57.5rem;
  gap: 2.5rem;
}

.registration__title {
  max-width: 39rem;
  text-transform: uppercase;
}

.registration__list {
  list-style: decimal;
  padding-left: 2rem;
  gap: 1rem;
}

.registration__item {
  line-height: 155%;
}

.registration__item-list {
  list-style: disc;
  padding-left: 2rem;
}

.registration__item-item {
  line-height: 155%;
}

.app__wrapper {
  position: relative;
  margin-bottom: 5rem;
  gap: 3rem;
}

.app__wrapper::after {
  right: 0;
  top: 18rem;
  width: 45rem;
  height: 45rem;
}

.app__content {
  max-width: 58rem;
  gap: 2.5rem;
}

.app__text {
  gap: 2.5rem;
}

.app__bottom {
  gap: 2.5rem;
}

.bonus__wrapper {
  position: relative;
  gap: 3rem;
}

.bonus__wrapper:before {
  left: -14rem;
  bottom: -24rem;
  width: 55rem;
  height: 45rem;
}

.bonus__wrapper::after {
  right: -12rem;
  bottom: 0;
  width: 55rem;
  height: 45rem;
}

.bonus__text-wrapper {
  gap: 2.5rem;
}

.bonus__text {
  gap: 2.5rem;
}

.bonus__list {
  gap: 2.5rem;
}

.bonus__item {
  max-width: 47.5rem;
}

.bonus__item-title {
  margin-bottom: 2rem;
  font-weight: 400;
  text-transform: uppercase;
}

.bonus__item-text {
  gap: 2.5rem;
}

.bonus__item-text--clamp {
  display: -webkit-box;
  overflow: hidden;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
}

.bonus__table {
  position: relative;
}

.bonus__table::before {
  content: "";
  position: absolute;
  right: 0;
  top: -18rem;
  width: 50rem;
  height: 18rem;
  background-image: -webkit-image-set(url(../img/bonus/bonus@2x.png) 2x, url(../img/bonus/bonus@1x.png) 1x);
  background-size: cover;
  background-repeat: no-repeat;
}

.vip::before {
  right: 0;
  bottom: -25rem;
  width: 55rem;
  height: 55rem;
}

.vip__wrapper {
  align-items: center;
  gap: 2.5rem;
}

.vip__content {
  min-width: 58rem;
  gap: 2.5rem;
}

.vip__text {
  gap: 2.5rem;
}

.vip__image {
  flex-shrink: 0;
  max-width: 88rem;
}

.tournament__table {
  overflow: auto;
}

.tournament__wrapper {
  gap: 5rem;
}

.tournament__content {
  gap: 2.5rem;
}

.tournament__text {
  gap: 2.5rem;
}

.slots__wrapper {
  gap: 4rem;
}

.slots__content {
  gap: 2.5rem;
}

.slots__text {
  gap: 2.5rem;
}

.slots__table::before {
  right: 0;
  top: 0;
  width: 45rem;
  height: 45rem;
}

.slots__table table thead th:last-child {
  min-width: 20rem;
}

.games::before {
  left: -27rem;
  top: 50%;
  width: 55rem;
  height: 55rem;
  transform: translateY(-50%);
}

.games__wrapper {
  position: relative;
}

.games__image {
  position: absolute;
  left: -32rem;
  top: 50%;
  width: 83rem;
  height: 62.5rem;
  transform: translateY(-50%);
}

.games__info {
  margin-left: auto;
  max-width: 67.5rem;
  gap: 2.5rem;
}

.games__text-wrapper {
  gap: 2.5rem;
}

.games__text {
  gap: 2rem;
}

.games__table {
  overflow: auto;
  border: 0.1rem solid var(--clr-primary-500);
  border-radius: 3.3rem;
  background-color: var(--clr-primary-700);
}

.games__table table {
  border-collapse: collapse;
}

.games__table table thead th {
  padding-top: 1.5rem;
  padding-right: 3rem;
  padding-bottom: 1.5rem;
  padding-left: 3rem;
  text-align: start;
}

.games__table table td {
  vertical-align: top;
  padding-top: 1.5rem;
  padding-right: 3rem;
  padding-bottom: 1.5rem;
  padding-left: 3rem;
  line-height: 155%;
}

.games__table table td:first-child {
  min-width: 30rem;
}

.games__table-list {
  list-style: disc;
  padding-left: 2rem;
}

.games__table-item {
  line-height: 155%;
}

.live__content-wrapper {
  gap: 6rem;
}

.live__wrapper {
  align-items: center;
  gap: 3rem;
}

.live__wrapper::after {
  left: 50%;
  top: 18rem;
  width: 85rem;
  height: 65rem;
  transform: translateX(-50%);
}

.live__content {
  min-width: 48rem;
  gap: 3rem;
}

.live__text {
  gap: 2rem;
}

.live__image {
  flex-shrink: 0;
  max-width: 85.5rem;
}

.live__body {
  justify-content: space-between;
  gap: 3rem;
}

.live__body-left {
  gap: 3rem;
}

.live__body-right {
  gap: 6rem;
}

.live__body-title {
  font-size: clamp(
    1.8rem,
    1.7280898876rem + 0.2247191011vw,
    2rem
  );
  text-transform: uppercase;
}

.live__body-image {
  max-width: 57.5rem;
}

.live__list {
  display: grid;
  grid-gap: 2.5rem;
  grid-template-columns: repeat(2, 1fr);
}

.live__item {
  font-size: clamp(
    1.8rem,
    1.7280898876rem + 0.2247191011vw,
    2rem
  );
}

.live__text-arrow {
  position: relative;
  margin-right: auto;
  margin-left: auto;
  max-width: 25.5rem;
  font-size: 1.4rem;
  line-height: 130%;
}

.live__text-arrow:before {
  content: "";
  position: absolute;
  left: -17rem;
  bottom: -2rem;
  width: 16rem;
  height: 3.2rem;
  background-image: url(../img/live/arrow.svg);
  background-size: cover;
  background-repeat: no-repeat;
}

.deposit::before {
  left: 50%;
  top: -15rem;
  width: 60rem;
  height: 60rem;
  transform: translateX(-50%);
}

.deposit__wrapper {
  gap: 4rem;
}

.deposit__wrapper-top {
  align-items: center;
  gap: 2.5rem;
}

.deposit__content {
  max-width: 57rem;
  gap: 2.5rem;
}

.deposit__text {
  gap: 2rem;
}

.deposit__image {
  max-width: 57.5rem;
}

.deposit__wrapper-bottom {
  gap: 3rem;
}

.deposit__info {
  max-width: 57.5rem;
  gap: 2rem;
}

.deposit__info-title {
  font-size: clamp(
    1.8rem,
    1.7280898876rem + 0.2247191011vw,
    2rem
  );
  line-height: 155%;
  text-transform: uppercase;
}

.deposit__info-text {
  gap: 2rem;
}

.deposit__info-list {
  list-style: disc;
  padding-left: 2rem;
  gap: 1rem;
}

.resume {
  position: relative;
  margin-top: 3rem;
  margin-bottom: -18rem;
  padding-top: 7rem;
  min-height: 100rem;
}

.resume::before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  z-index: -1;
  width: 100%;
  height: 100%;
  background-image: -webkit-image-set(url(../img/resume/bg@2x.jpg) 2x, url(../img/resume/bg@1x.jpg) 1x);
  background-size: cover;
  background-repeat: no-repeat;
}

.resume__content {
  position: relative;
  overflow: hidden;
  border: 0.1rem solid var(--clr-primary-550);
  border-radius: 3.3rem;
  padding: 10rem;
  min-height: 67rem;
  background-color: var(--clr-default-600);
}

.resume__content::before {
  left: 50%;
  bottom: -28rem;
  width: 55rem;
  height: 55rem;
  transform: translateX(-50%);
}

.resume__info {
  gap: 2.5rem;
}

.resume__text {
  z-index: 1;
  gap: 2rem;
}

.resume__image {
  position: absolute;
  left: 50%;
  bottom: -16rem;
  width: 84rem;
  height: 40rem;
  transform: translateX(-50%);
}

@media (max-width: 80em) {
  .live__text-arrow {
    margin-right: initial;
  }
}

@media (max-width: 75em) {
  .hero__content {
    max-width: 50rem !important;
  }

  .bonus__item-text--clamp {
    display: initial;
    overflow: initial;
    -webkit-box-orient: initial;
    -webkit-line-clamp: initial;
  }

  .bonus__table::before {
    display: none;
  }

  .bonus__table {
    overflow: auto;
  }

  .games__info {
    max-width: 50rem;
  }

  .games__table table td:first-child {
    min-width: 15rem !important;
  }
}

@media (max-width: 70em) {
  .footer {
    padding-top: 3rem;
    padding-bottom: 3rem;
  }

  .footer__wrapper {
    flex-direction: column;
  }

  .resume {
    margin-bottom: initial;
    padding-bottom: 7rem;
    min-height: initial;
  }

  .resume::before {
    display: none;
  }
}

@media (max-width: 62em) {
  .section {
    padding-top: 8rem;
  }

  .table table thead th:first-child {
    min-width: 20rem;
  }

  .hero:before {
    display: none;
  }

  .hero:after {
    transform: translate(-50%, 22%);
  }

  .hero {
    padding-top: 4rem;
  }

  .hero__wrapper {
    flex-direction: column;
  }

  .hero__content {
    max-width: initial !important;
  }

  .hero__image {
    max-width: 55rem;
  }

  .info__table-wrapper::before,
  .info__table-wrapper::after {
    display: none;
  }

  .info__table-wrapper {
    padding-top: initial;
    padding-bottom: initial;
  }

  .benefits__body {
    flex-direction: column;
  }

  .benefits__body-wrapper {
    margin-right: initial !important;
    min-width: 100%;
    max-width: initial !important;
  }

  .registration::after {
    display: none;
  }

  .registration__wrapper {
    flex-direction: column-reverse;
    align-items: initial;
  }

  .registration__image {
    margin-right: initial !important;
  }

  .registration__info {
    margin-left: initial;
    max-width: initial !important;
  }

  .registration__link {
    margin-right: auto;
    margin-left: auto;
  }

  .app__wrapper {
    flex-direction: column;
  }

  .app__content {
    max-width: initial;
  }

  .bonus__list {
    flex-direction: column;
  }

  .bonus__item {
    max-width: initial;
  }

  .vip__wrapper {
    flex-direction: column;
  }

  .vip__content {
    max-width: initial !important;
  }

  .games__wrapper {
    flex-direction: column-reverse;
    gap: 2rem;
  }

  .games__image {
    margin-right: auto !important;
    margin-left: auto;
  }

  .games__info {
    max-width: initial !important;
  }

  .live__wrapper {
    flex-direction: column;
  }

  .live__content {
    max-width: initial !important;
  }

  .live__body {
    flex-direction: column;
    align-items: initial;
  }

  .live__body-title {
    text-align: center;
  }

  .live__body-image {
    margin-right: auto;
    margin-left: auto;
  }

  .live__text-arrow {
    display: none;
  }

  .deposit__wrapper-top {
    flex-direction: column;
  }

  .deposit__content {
    max-width: initial;
  }

  .deposit__wrapper-bottom {
    flex-direction: column;
  }

  .deposit__info {
    max-width: initial;
  }

  .resume {
    margin-top: 5rem;
    padding-top: initial;
    padding-bottom: initial;
  }

  .resume__content::before {
    display: none;
  }

  .resume__content {
    padding: 3rem;
    gap: 3rem;
  }

  .resume__image {
    position: initial;
    left: initial;
    bottom: initial;
    width: initial;
    height: initial;
    max-width: 84rem;
    transform: initial;
  }
}

@media (max-width: 111.25em) {
  .hero {
    padding-bottom: initial;
    min-height: initial;
  }

  .hero__wrapper {
    align-items: center;
    gap: 3rem;
  }

  .hero__content {
    flex-shrink: 0;
    max-width: 67rem;
  }

  .hero__image {
    position: initial;
    right: initial;
    top: initial;
    width: initial;
    height: initial;
  }
}

@media (max-width: 111.875em) {
  .vip__content {
    min-width: initial;
    max-width: 50rem;
  }

  .vip__image {
    flex-shrink: initial;
  }
}

@media (max-width: 105.625em) {
  .games__wrapper {
    align-items: center;
  }

  .games__image {
    position: initial;
    left: initial;
    top: initial;
    margin-right: 3rem;
    width: initial;
    transform: initial;
    height: initial;
    max-width: 83rem;
  }

  .games__info {
    max-width: 55rem;
  }

  .games__table table td:first-child {
    min-width: 20rem;
  }
}

@media (max-width: 97.5em) {
  .benefits::before {
    display: none;
  }

  .benefits__body {
    align-items: center;
    gap: 3rem;
  }

  .benefits__body-wrapper {
    flex-shrink: 0;
    max-width: 60rem;
  }

  .benefits__image {
    position: initial;
    right: initial;
    top: initial;
    width: initial;
    height: initial;
    max-width: 84rem;
  }

  .live__content {
    min-width: initial;
    max-width: 48rem;
  }

  .live__image {
    flex-shrink: initial;
  }
}

@media (max-width: 86.875em) {
  .registration__wrapper {
    min-height: initial;
  }

  .registration__image {
    position: initial;
    left: initial;
    top: initial;
    width: initial;
    height: initial;
    max-width: 87rem;
    transform: initial;
  }

  .registration__info {
    max-width: 50rem;
  }

  .registration__title {
    max-width: initial;
  }
}

@media (max-width: 67.5em) {
  .menu__list {
    flex-direction: column;
    margin: auto;
    gap: 3rem;
    padding-block: 4rem;
  }

  .menu__item a {
    font-weight: 600;
    font-size: 1.8rem;
  }

  .menu {
    position: fixed;
    right: -100%;
    top: 0;
    z-index: 10;
    display: flex;
    overflow: auto;
    margin-right: initial;
    width: 100%;
    height: 100%;
    max-width: 32rem;
    background-color: var(--clr-default-500);
    transition: right var(--transition);
  }

  .menu--active {
    right: 0;
    transition: right var(--transition);
  }

  .burger {
    position: relative;
    z-index: 15;
    display: block;
    width: 2.5rem;
    height: 2rem;
    background-color: transparent;
  }

  .burger::after,
  .burger::before,
  .burger span {
    content: "";
    position: absolute;
    left: 0;
    width: 100%;
    height: 0.4rem;
    background-color: var(--clr-default-100);
  }

  .burger::before {
    top: 0;
    transition: transform var(--transition), top var(--transition);
  }

  .burger::after {
    bottom: 0;
    transition: transform var(--transition), bottom var(--transition);
  }

  .burger span {
    top: 0.8rem;
    transition: transform var(--transition);
  }

  .burger--active::before {
    top: 0.8rem;
    transform: rotate(45deg);
    transition: transform var(--transition), top var(--transition);
  }

  .burger--active::after {
    bottom: 0.8rem;
    transform: rotate(-45deg);
    transition: transform var(--transition), bottom var(--transition);
  }

  .burger--active span {
    transform: scale(0);
    transition: transform var(--transition);
  }

  .header__link {
    margin-left: auto;
  }

  .info {
    z-index: 0;
  }
}

@media (max-width: 67.5em) and (max-width: 35.9375em) {
  .burger {
    margin-left: auto;
  }
}

@media (max-width: 47.9375em) {
  .table table {
    min-width: 50rem;
  }

  .table table thead th:first-child {
    min-width: 12rem;
  }

  .table table thead th:nth-child(2) {
    width: initial;
  }

  .table table thead th:nth-child(3) {
    width: initial;
  }

  .logo {
    max-width: 13rem;
  }

  .header {
    min-height: 10rem;
  }

  .footer__menu {
    flex-direction: column;
  }

  .info__table tbody td:first-child {
    min-width: 20rem;
  }

  .benefits__body-wrapper {
    flex-direction: column;
  }

  .bonus__text-wrapper {
    gap: 1.5rem;
  }

  .bonus__text {
    gap: 1.5rem;
  }

  .bonus__item-text {
    gap: 1.5rem;
  }

  .slots__table {
    overflow-x: auto;
  }
}

@media (max-width: 35.9375em) {
  .section {
    padding-top: 5rem;
  }

  .table table thead th {
    padding-right: 1.5rem;
    padding-left: 1.5rem;
  }

  .table table tbody tr td {
    padding-right: 1.5rem;
    padding-left: 1.5rem;
  }

  .main__link {
    min-width: 100%;
  }

  .info__table tbody td:first-child {
    min-width: 16rem;
  }

  .info__table tbody td:last-child {
    min-width: 30rem;
  }

  .info__table {
    margin-bottom: 3rem;
    border-radius: 2rem;
    padding-top: 2rem;
    padding-right: 1.5rem;
    padding-bottom: 2rem;
    padding-left: 1.5rem;
  }

  .benefits__wrapper {
    gap: 3rem;
  }

  .benefits__plus-item {
    padding-left: 6rem;
  }

  .benefits__item-text--1::before,
  .benefits__item-text--2::before,
  .benefits__item-text--3::before,
  .benefits__item-text--4::before,
  .benefits__item-text--5::before,
  .benefits__item-text--6::before,
  .benefits__item-text--7::before,
  .benefits__item-text--8::before,
  .benefits__item-text--9::before,
  .benefits__item-text--10::before {
    left: -4.5rem;
    width: 3.2rem;
    height: 3.2rem;
  }

  .registration__link {
    width: initial;
    min-width: initial;
    text-align: center;
  }

  .games__table table td:first-child {
    min-width: 10rem !important;
  }

  .games__table table td {
    padding-right: 1.5rem;
    padding-left: 1.5rem;
  }

  .live__list {
    grid-template-columns: 1fr;
    gap: 1.5rem;
  }

  .resume__content {
    border-radius: 2rem;
    padding: 1.5rem;
  }
}

@media (hover: hover) {
  .main__link:hover {
    background-color: var(--clr-primary-310);
    transition: background-color var(--transition);
  }

  .main__link:hover::before {
    transform: translate(-50%, 0%);
    transition: transform var(--transition);
  }

  .menu__item:hover::before {
    width: 100%;
    transition: width 0.3s;
  }

  .footer__item:hover::before {
    width: 100%;
    transition: width 0.3s;
  }
}

@media (hover) {
  .typography .link:hover {
    color: var(--clr-secondary-400);
  }
}