.hero {
  position: relative;
  min-height: 55.5rem;

  &:before, &:after {
    content: '';
    position: absolute;
    z-index: -1;
    background-color: var(--clr-primary-400);
    filter: blur(14rem);
    mix-blend-mode: color-dodge;
  }

  &:before {
    left: 0;
    top: 0;
    width: 45rem;
    height: 44rem;
    transform: translate(-43%, -83%);

    @media (max-width: em(992)) {
      display: none;
    }
  }

  &:after {
    left: 50%;
    top: 50%;
    width: 32rem;
    height: 32rem;
    transform: translate(-50%, -200%);

    @media (max-width: em(992)) {
      transform: translate(-50%, 22%);
    }
  }

  @media (max-width: em(1780)) {
    padding-bottom: initial;
    min-height: initial;
  }

  @media (max-width: em(992)) {
    padding-top: 4rem;
  }

  &__wrapper {
    position: relative;

    @media (max-width: em(1780)) {
      align-items: center;
      gap: 3rem;
    }

    @media (max-width: em(992)) {
      flex-direction: column;
    }
  }

  &__content {
    max-width: 63rem;
    gap: 3rem;

    @media (max-width: em(1780)) {
      flex-shrink: 0;
      max-width: 67rem;
    }

    @media (max-width: em(1200)) {
      max-width: 50rem !important;
    }

    @media (max-width: em(992)) {
      max-width: initial !important;
    }
  }


  &__image {
    position: absolute;
    right: -40rem;
    top: -33rem;
    z-index: 0;
    width: 89.5rem;
    height: 78.5rem;

    @media (max-width: em(1780)) {
      position: initial;
      right: initial;
      top: initial;
      width: initial;
      height: initial;
    }

    @media (max-width: em(992)) {
      max-width: 55rem;
    }
  }
}

