.tournament {

  &__table {
    overflow: auto;
  }

  &__wrapper {
    gap: 5rem;
  }

  &__content {
    gap: 2.5rem;
  }

  &__text {
    gap: 2.5rem;
  }
}