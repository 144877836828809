.deposit {

  &::before {
    left: 50%;
    top: -15rem;
    width: 60rem;
    height: 60rem;
    transform: translateX(-50%);
  }

  &__wrapper {
    gap: 4rem;
  }

  &__wrapper-top {
    align-items: center;
    gap: 2.5rem;
    @media (max-width: em(992)) {
      flex-direction: column;
    }
  }

  &__content {
    max-width: 57rem;
    gap: 2.5rem;

    @media (max-width: em(992)) {
      max-width: initial;
    }
  }

  &__title {

  }

  &__text {
   gap: 2rem;
  }

  &__image {
    max-width: 57.5rem;
  }

  //bottom 

  &__wrapper-bottom {
    gap: 3rem;

    @media (max-width: em(992)) {
      flex-direction: column;
    }
  }

  &__info {
    max-width: 57.5rem;
    gap: 2rem;

    &-title {
      font-size: fluid-font(2rem, 1.8rem);
      line-height: 155%;
      text-transform: uppercase;
    }

    &-text {
      gap: 2rem;
    }

    &-list {
      list-style: disc;
      padding-left: 2rem;
      gap: 1rem;
    }

    @media (max-width: em(992)) {
      max-width: initial;
    }
  }
}