.bonus {
  &__wrapper {
    position: relative;
    gap: 3rem;

    &:before {
      left: -14rem;
      bottom: -24rem;
      width: 55rem;
      height: 45rem;
    }

    &::after {
      right: -12rem;
      bottom: 0;
      width: 55rem;
      height: 45rem;
    }
  }

  &__text-wrapper {
    gap: 2.5rem;

    @media (max-width: em(767)) {
      gap: 1.5rem;
    }
  }

  &__text {
    gap: 2.5rem;

    @media (max-width: em(767)) {
      gap: 1.5rem;
    }
  }

  &__list {
    gap: 2.5rem;

    @media (max-width: em(992)) {
      flex-direction: column;
    }
  }

  &__item {
    max-width: 47.5rem;

    @media (max-width: em(992)) {
      max-width: initial;
    }
  }

  &__item-title {
    margin-bottom: 2rem;
    font-weight: 400;
    text-transform: uppercase;
  }

  &__item-text {
    gap: 2.5rem;

    @media (max-width: em(767)) {
      gap: 1.5rem;
    }

    &--clamp {
      display: -webkit-box;
      overflow: hidden;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 3;

      @media (max-width: em(1200)) {
        display: initial;
        overflow: initial;
        -webkit-box-orient: initial;
        -webkit-line-clamp: initial;
      }
    }
  }

  &__table {
    position: relative;

    &::before {
      content: "";
      position: absolute;
      right: 0;
      top: -18rem;
      width: 50rem;
      height: 18rem;
      background-image: -webkit-image-set(
        url(../img/bonus/bonus@2x.png) 2x,
        url(../img/bonus/bonus@1x.png) 1x
      );
      background-size: cover;
      background-repeat: no-repeat;

      @media (max-width: em(1200)) {
        display: none;
      }
    }

    @media (max-width: em(1200)) {
      overflow: auto;
    }
  }
}
